*
{
    margin: 0;
    padding: 0;
}

/*
html,
body
{
    overflow: hidden;
}
*/

.webgl
{
    z-index: -1; /* Behind the content */
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

html{
    background: #1e1a20;
    scroll-behavior: smooth;
}


.section
{
    z-index: 1; 
    overflow: hidden;
    display: flex;
    height: 100vh;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;  /* Centers children horizontally */
    align-items: center;      /* Centers children vertically */
}


section:nth-child(odd)
{
    justify-content: flex-end;
}

.container{
    margin: 150px auto;
    width: 80vw
}


.section header{
    position: relative;
    font-family: 'Cabin', sans-serif;
}

.section header .name h2{
    display: block;
    font-size: 84px;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    color: white;
    text-align: center;
    padding-bottom: 20px;
}

.navbar {
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

/* Apply padding-left when the width of the viewport is less than 802px */
@media screen and (max-width: 801px) {
    .navbar  {
        padding-left: 20px; /* Adjust the padding value to your preference */
    }
}



nav ul li{
    list-style: none;
    display: inline-block;
}

nav ul li a{
    text-decoration: none;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 15px;
    font-weight: 600;
    border-bottom: 2px solid transparent;
    transition: .4s;
    line-height: 80px;
    text-align: center;

}
nav ul li a:hover,
nav ul li a:focus{
    border-bottom: 2px solid white;
}



.section .col-md-4 h2 {
    font-size: 50px;
    font-weight: 600;
    color: white;
    text-align: center;
    white-space: nowrap;
    bottom: 0; /* Reset or remove this, as it might cause the h2 to be pushed out of its container. */
    left: 0;   /* Reset or remove this for the same reason. */
    margin: 0; /* Reset the margin values to ensure the heading remains within its parent div. */
    text-align: center; /* Center the heading */
}

.section .container {
    max-width: 100%;
    display: flex;
    flex-direction: column; /* Add this to stack items vertically */
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
}

.section .card {
    width: 600px;
    height: 200px;
    background: black;
    margin: 30px 10px;
    flex-direction: column;
    box-shadow: 20px 20px 50px rgb(0,0,0,0.5);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255,255,255,0.5);
    border-left: 1px solid rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
    max-width: 100%; /* Ensure the card doesn't exceed the width of its parent */
    /* ...existing styles... */
    margin: 30px 0; /* Adjust margins as required. Here, I've reset the horizontal margins to zero. */
}






.section .card .content {
    text-align: center;   
    font-size: 20px; 
    padding: 20px;
    font-family: 'Cabin', sans-serif;
}

/* Apply padding-left when the width of the viewport is less than 802px */
@media screen and (max-width: 801px) {
    .section .card .content {
        font-size: 15px;  /* Adjust the padding value to your preference */
    }
}

.section .card .content p {
    color: #eee;
}

.section .heading {
    font-weight: 600;
    color: white;
    font-family: 'Cabin', sans-serif;
    white-space: nowrap;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 4%; /* Adjusted margin-top for positioning */
    margin-bottom: 2%; /* Adjusted margin-bottom for spacing between heading and card */
    text-align: center;
}


.process-wrapper{
    margin: auto;
    max-width: 1080px;
}





.content-container span{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 30px;
}
input[type = radio]
{
    display: none;
    position: absolute;
}

h1{
    font-weight: 900;
    margin-top: 20px;
    text-align: center;
}

.content-container p{
    padding: 30px 40px;
    text-align: center;
    font-weight: bolder;
    font-size: 20px;
}





#section span{
    font-smooth: 3rem;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

h3{
    font-family: 'Cabin', sans-serif;
    font-size: 30px;
    margin-bottom: 18px;
    margin-left: 20px;
}
h4{
    font-family: 'Cabin', sans-serif;
    font-size: 25px;
    margin-bottom: 18px;
    margin-left: 20px;
}

.profile-section p{
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    margin-bottom: 7px;
    margin-left: 70px;

}

.settings-section p{
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    margin-bottom: 7px;
    margin-left: 70px;

}

.posts-section p{
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    margin-bottom: 7px;
    margin-left: 70px;

}

.section .about-me {
    font-weight: 600;
    color: white;
    font-family: 'Cabin', sans-serif;
    white-space: nowrap;
    text-align: center;

}

.about-container{
    width: 200vw;
    font-size: 40px;
    color: white;
    font-family: 'Cabin', sans-serif;
    overflow: auto;
}

.about-container, .about-container * {
    box-sizing: border-box;
}

.about-container, #education-section {
    max-width: 100%;
}



#education-section{
    position: relative;
    width: 50%;
    background: black;
    border-radius: 4px;
    padding: 50px;
    color: #fff;
    box-shadow: 20px 20px 50px rgb(0,0,0,0.5);
    background: rgba(43, 42, 42, 0.6);
    border-radius: 15px;
    border-top: 1px solid rgba(255,255,255,0.5);
    border-left: 1px solid rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
    display: block;

}

#start{

    background: transparent;
    color: aliceblue;
    width: 280px;
    height: 60px;
    position: relative;
    bottom: calc(50% -30px);
    border: 1px solid #ffffff;
    border-radius: 3px;
    cursor: pointer;
    transition-duration: 600ms;
    margin: auto;
    text-align: center;
    font-size: 20px;
    font-family: 'Cabin', sans-serif;



}

#start:hover{
    background: #ffffff;
    color: #000000;
}

video{
    display: none;
}

.contact-container{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
form{
    background: black;
    box-shadow: 20px 20px 50px rgb(0,0,0,0.5);
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    padding: 2vw 4vw;
    width: 90%;
    max-width: 600px;
    border-radius: 15px;
    border-top: 1px solid rgba(255,255,255,0.5);
    border-left: 1px solid rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
    justify-content: center;

}

.textbox{
    position:relative;
}

form h3{
    color: rgb(255, 255, 255);
    font-weight: 800;
    margin-bottom: 20px;
    margin-left: 28%;
}

form input, form textarea{
    border: 0;
    margin: 10px 0;
    padding: 20px;
    outline: none;
    background: #f5f5f5;
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
}

form button{
    padding: 15px;
    background: #000000;
    color: #fff;
    font-size: 18px;
    border: 0;
    outline: none;
    cursor: pointer;
    width: auto;
    margin: 20px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;

}








.tab_wrapper{
	width: 80%;
	margin: 100px auto;
}

.tabs ul{
	display: flex;
    background: black;
    border-radius: 4px;
    color: #fff;
    box-shadow: 20px 20px 50px rgb(0,0,0,0.5);
    background: rgba(43, 42, 42, 0.6);
    border-radius: 15px;
    border-top: 1px solid rgba(255,255,255,0.5);
    border-left: 1px solid rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
    margin-bottom: 25px;
}

.tabs ul li{
	width: 400px;
	padding: 15px;
	/*border-right: 1px solid #dddddd;*/
	text-align: center;
	color: #3b3b3b;
	font-size: 14px;
	font-weight: 600;
    font-family: 'Cabin', sans-serif;
	letter-spacing: 2px;
	cursor: pointer;
	transition: all 0.2s ease;
	position: relative;
  list-style-type: none;
}

.tabs .text{
    font-family: 'Cabin', sans-serif;
  font-size: 15px;
}


/*experience*/

.tabs ul li:last-child{
	border-right: 0px;
}

.tabs ul li:before{
	content: "";
	position: absolute;
	bottom: -1px;
	left: 0;
	width: 100%;
	height: 2px;
	opacity: 0;
	transition: all 0.2s ease;
}

.tabs ul li:hover,
.tabs ul li.active{
	color: #ffffff;
}

.tabs ul li:hover:before,
.tabs ul li.active:before{
	opacity: 1;
}

.t_content{
    width: 50%;
    margin: auto;
    background: black;
    border-radius: 4px;
    padding: 30px;
    color: #fff;
    box-shadow: 20px 20px 50px rgb(0,0,0,0.5);
    background: rgba(43, 42, 42, 0.6);
    border-radius: 15px;
    border-top: 1px solid rgba(255,255,255,0.5);
    border-left: 1px solid rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
    margin-top: 50px;
}

.tab_wrap {
    min-height: 300px; /* Adjust this value based on your needs */
    overflow-y: auto;
}


.tab_wrap .title{
	font-size: 25px;
    font-family: 'Cabin', sans-serif;
	margin-bottom: 15px;
	font-weight: 600;
}

.tab_wrap .tab_content h1{
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
    line-height: 22px;
	margin-bottom: 15px;
}

.tab_wrap .tab_content p{
	font-size: 15px;
    font-family: 'Cabin', sans-serif;
  line-height: 22px;
	margin-bottom: 15px;
}

.tab_content span{
  display: block;
}

table{
  width: 100%;
}

td{
  padding: 12px 15px;
}

tbody tr{
  border-bottom: 1px solid #dddddd;
}

tbody tr:nth-of-type(odd){
  background-color: #f3f3f3;

}

.tab_button{
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 8px 20px;
  background: rgb(213, 221, 243);
  color: floralwhite;
  border-radius: 40px;
  letter-spacing: 1px;
  margin-right: 20px;
}

.tab_button a{
  color: #000000;
  text-decoration: none;
}

.icon{
  color: #374ab8;
  padding: 0 2px;
}

.tab_content{
  margin-bottom: 10px;
}

.experience_title {
    font-size: 40px;  /* Adjust as needed */
    text-align: center;
    margin-bottom: 60px;  /* Adjust the spacing as desired */
    font-family: 'Cabin', sans-serif;
    color: #dddddd;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: white;
    text-align: center;

}


@media screen and (max-width: 768px){
    .tabs ul{
      display: flex;
      border-radius: 5px;
      margin-bottom: 25px;
      flex-wrap: wrap;
    }
  }
  
  @media screen and (max-width: 745px){
    .tabs ul{
      display: flex;
      border-radius: 5px;
      margin-bottom: 25px;
      padding: 0px 130px;
      flex-wrap: wrap;
    }
  }
  
  @media screen and (max-width: 537px){
    .tabs ul{
      display: flex;
      border-radius: 5px;
      margin-bottom: 25px;
      padding: 0px 0px;
      flex-wrap: wrap;
    }
  }


.e_content{
    width: 50%;
    margin: auto;
    background: black;
    border-radius: 4px;
    padding: 30px;
    color: #fff;
    box-shadow: 20px 20px 50px rgb(0,0,0,0.5);
    background: rgba(43, 42, 42, 0.6);
    border-radius: 15px;
    border-top: 1px solid rgba(255,255,255,0.5);
    border-left: 1px solid rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
    margin-top: 50px;
    box-sizing: border-box;
    word-wrap: break-word;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-size: 1em;  
    
}
/* Set your text elements to use vw units */


.education-h2{
    padding-bottom: 30px;
    font-size: 65px;

}



#education-h3 {
    font-family: 'Cabin', sans-serif;
    font-size: 25px;
    margin-bottom: 18px;
    margin-left: 20px;
    text-align: center;
    color: #ffffff;
}

@media screen and (max-width: 400px){
    #education-h3{
        margin-left: 0px;
        font-size: 20px;
    }
  }

#education-h4, #education-h2 {
    font-family: 'Cabin', sans-serif;
    margin-bottom: 18px;
}

#education-h4 {
    font-family: 'Cabin', sans-serif;
    font-size: 21px;
    text-align: center;
    color: #ffffff;
}

@media screen and (max-width: 400px){
    #education-h4{
        font-size: 20px;
        margin-left: 0px;
    }
  }

#education-h2 {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
}

@media screen and (max-width: 400px){
    #education-h2-GPA{
        font-size: 18px;
        margin-left: 0px;
    }
  }

#education-h2-GPA {
    font-family: 'Cabin', sans-serif;
    color: #f0f0f0;
    text-align: center;
}

@media screen and (max-width: 400px){
    #education-h2-GPA{
        font-size: 20px;
        margin-left: 0px;
    }
  }

#education-p {
    font-family: 'Cabin', sans-serif;
    font-size: 15px;
    margin-bottom: 18px;
}


.send_title {
    margin-top: 30px;
    font-size: 30px;  /* Adjust as needed */
    text-align: center;
    margin-bottom: 30px;  /* Adjust the spacing as desired */
    font-family: 'Cabin', sans-serif;
    color: #dddddd;
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: white;
    text-align: center;

}
